
const Data = [
    {
        id: 1,
        category: "education",
        icon: "icon-graduation",
        year: "2021 - present",
        title: "Bachelor's Degree",
        desc: "My current Bachelor's degree studies at XAMK as a software engineer."
    },
    {
        id: 2,
        category: "education",
        icon: "icon-graduation",
        year: "2018 - 2019",
        title: "Vocational qualification in immediate supervisor",
        desc: "Studies to further my knowledge and skills as an immediate supervisor."
    },
    {
        id: 3,
        category: "education",
        icon: "icon-graduation",
        year: "2008 - 2011",
        title: "Undergraduate",
        desc: "My undergraduate studies in a high school."
    },
    {
        id: 4,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "C#",
        desc: "Developed efficient and scalable software solutions using C# through various academic projects and a rewarding internship."
    },
    {
        id: 5,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Database Management",
        desc: "Experienced in designing, implementing, and optimizing databases such as MySQL, MongoDB, and PostgreSQL for both academic and personal projects."
    },
    {
        id: 6,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Python",
        desc: "Proficient in Python for data analysis and machine learning projects, leveraging its extensive libraries to solve complex problems."
    },
    {
        id: 7,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "React",
        desc: "Built intuitive and responsive web applications using React, focusing on clean code architecture and an optimal user experience."
    },
    {
        id: 8,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Node.js",
        desc: "Specialized in back-end development with Node.js, including building RESTful APIs and optimizing server-side applications. Currently refining a back-end for my thesis project."
    },
    {
        id: 9,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "GitHub",
        desc: "Skilled in using GitHub for version control and collaborative development, continuously expanding my portfolio with public projects."
    },
    {
        id: 10,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "CI/CD",
        desc: "Implemented CI/CD pipelines using GitHub Actions to automate build, testing, and deployment processes for reliable software delivery."
    },
    {
        id: 11,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Firebase",
        desc: "I've deployed this website using Firebase and utilized Firebase Authentication."
    },
    {
        id: 12,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Cloudflare",
        desc: "Experience with Cloudflare CDN, caching, and security features."
    }
    
    
]

export default Data
import './about.css';


const About = () => {
    return (
        <section className="about container section" id='about'>
            <h2 className="section_title">About Me</h2>

            <div className='about_container grid'></div>

                <div className='about_data grid'>
                    <div className='about_info'>
                        <p className='about_desc'>Hello there. I'm Jere, a last-year software engineering student at South-Eastern Finland 
                        University of Applied Sciences in Mikkeli. I'm currently working on my thesis refactoring a Node.js-based back-end with Typescript in an Unreal Engine 5 application. 
                        My passion for computers and the internet has driven me to pursue a career in software engineering.
                        <br/><br/>Although I am still early in my professional journey, my commitment to continuous learning and problem-solving
                        has enabled me to gain hand-on experience through both my studies and personal projects. I am now seeking opportunities
                        that allow me to apply everything I've learned and further develop my skills.</p>
                        <a href="https://drive.google.com/file/d/1h6XI0eijLwySdBXY_jWGq89JpCrV1OQx/view" download="CV_Janhunen" className='btn'>Download my CV</a>
                    </div>

                    <div className="about_skills grid">
                        <h2>Skills at a Glance</h2>
                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>Development</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Strong foundation in application development, code optimization, and software architecture.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>Continuous Learning</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Committed to lifelong learning and quick adaptation to new technologies.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>Python</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Proficient in Python with a strong capability to build and maintain robust applications.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>C#</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Advanced skills in C# focused on creating efficient and scalable software solutions.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>React</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Experienced in React for developing dynamic and responsive user interfaces.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>Node.js</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Solid expertise in Node.js for robust backend development and (REST) API design.</p>
                            </div>
                        </div>

                        <div className="skills_data">
                            <div className="skills_titles">
                                <h3 className="skills_name"><b>Robot Framework</b></h3>
                            </div>
                            <div className="skills_description">
                                <p>Skilled in using Robot Framework for automated testing and continuous integration.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default About
import Work1 from "../../assets/kauppa.jpg"
import Work2 from "../../assets/physics.jpg"
import Work3 from "../../assets/face.jpg"
import Work4 from "../../assets/cleanarch.jpg"
import Work5 from "../../assets/micro.jpg"
import Work6 from "../../assets/peli.jpg"

const Menu = [
    {
        id: 1,
        image: Work4,
        title: "C# Clean Architecture Example",
        description: "An example of a Clean Architecture application in C#.",
        link: "https://github.com/DJeJa003/CleanArchitectureExample"
    },
    {
        id: 2,
        image: Work5,
        title: "C# Microservice Example",
        description: "A simple example of a Microservice application in C#. Fetches the spot price of electricity from a public API.",
        link: "https://github.com/DJeJa003/SpotPriceMicroservice",
    },
    {
        id: 3,
        image: Work6,
        title: "Unreal Engine 5 game",
        description: "A game made for a school project with Unreal Engine 5. The game is soulslike and settles in feudal Japan. Source code not available.",
        
    },
    {
        id: 4,
        image: Work3,
        title: "Face Detection & Age Prediction",
        description: "A Python program that recognizes faces from a picture, and uses a machine learning model to predict the age of the person(s).",
        link: "https://github.com/DJeJa003/FaceRecognition-AgePrediction"
    },
    {
        id: 5,
        image: Work1,
        title: "React webshop",
        description: "A webshop built with React to showcase my learning and knowledge. Server for this was created with Node.js.",
        link: "https://github.com/DJeJa003/React-nodejs-Store"
    },
    
    {
        id: 6,
        image: Work2,
        title: "Unity Physics Game",
        description: "My first Unity project ever done in a group with a strict timelimit. We made a game made for physics calculations, for example dropping the ball or skijumping.",
        link: "https://github.com/DJeJa003/Fysiikka-1-/tree/master"
    },
    
    
    
    
]
export default Menu
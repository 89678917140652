import { useState } from 'react';
import "./portfolio.css";
import Menu from './menu';

const Portfolio = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3;

    const nextSlide = () => {
        if (currentIndex < Menu.length - itemsPerPage) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <section className="work container section" id="portfolio">
            <h2 className="section_title">Past Projects</h2>

            <div className="work_wrapper">
                <button className="arrow left" onClick={prevSlide} disabled={currentIndex === 0}>
                    &#10094;
                </button>

                <div className="work_container">
                    {Menu.slice(currentIndex, currentIndex + itemsPerPage).map((elem) => {
                        const { id, image, title, description, link } = elem;
                        return (
                            <div className="work_card" key={id}>
                                <div className="work_thumbnail">
                                    <img src={image} alt="Screenshot of the work" className="work_img" />
                                    <div className="work_mask"></div>
                                </div>

                                <span className="work_description">{description}</span>
                                <h3 className="work_title">{title}</h3>
                                <a href={link} className="work_button">
                                    <i className="icon-link work_button-icon"></i>
                                </a>
                            </div>
                        );
                    })}
                </div>

                <button className="arrow right" onClick={nextSlide} disabled={currentIndex >= Menu.length - itemsPerPage}>
                    &#10095;
                </button>
            </div>
        </section>
    );
};

export default Portfolio;